@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

body {
  background: url("img/wallpaper.jpg");
  background-size: cover;
}
.todo-app {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 520px;
  min-height: 500px;
  background: #161a2b;
  text-align: center;
  margin: calc(100vh / 12) auto;
  border-radius: 10px;
  padding-bottom: 32px;
}

h1 {
  margin: 32px 0;
  color: #fff;
  font-size: 24px;
}

.complete {
  text-decoration: line-through;
  opacity: 0.4;
}

.todo-form {
  margin-bottom: 32px;
}

.todo-input {
  padding: 14px 32px 14px 16px;
  border-radius: 4px 0 0 4px;
  border: 2px solid #5d0cff;
  outline: none;
  width: 320px;
  background: transparent;
  color: #fff;
}

.todo-input::placeholder {
  color: #e2e2e2;
}

.todo-button {
  padding: 16px;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  outline: none;
  background-image: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgba(155, 0, 250, 1) 100%
  );
  color: #fff;
  text-transform: capitalize;
}

.todo-input.edit {
  border: 2px solid #149fff;
}

.todo-button.edit {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
  padding: 16px 22px;
}

.todo-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.todo-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px auto;
  color: #fff;
  background: linear-gradient(
    90deg,
    rgba(255, 118, 20, 1) 0%,
    rgba(255, 84, 17, 1) 100%
  );

  padding: 16px;
  border-radius: 5px;
  width: 90%;
}

.todo-row:nth-child(4n + 1) {
  background: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgba(155, 0, 250, 1) 100%
  );
}

.todo-row:nth-child(4n + 2) {
  background: linear-gradient(
    90deg,
    rgba(255, 12, 241, 1) 0%,
    rgba(250, 0, 135, 1) 100%
  );
}

.todo-row:nth-child(4n + 3) {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
}

.icons {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.delete-icon {
  margin-right: 5px;
  color: #fff;
}

.edit-icon {
  color: #fff;
}

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 13s;
}
.firefly:nth-child(1)::after {
  animation-duration: 13s, 5182ms;
  animation-delay: 0ms, 1069ms;
}

@keyframes move1 {
  0% {
    transform: translateX(-27vw) translateY(32vh) scale(0.76);
  }
  5% {
    transform: translateX(-19vw) translateY(-25vh) scale(0.28);
  }
  10% {
    transform: translateX(-40vw) translateY(-42vh) scale(0.32);
  }
  15% {
    transform: translateX(34vw) translateY(-7vh) scale(0.52);
  }
  20% {
    transform: translateX(-19vw) translateY(-48vh) scale(0.32);
  }
  25% {
    transform: translateX(-14vw) translateY(45vh) scale(0.82);
  }
  30% {
    transform: translateX(-13vw) translateY(-40vh) scale(0.84);
  }
  35% {
    transform: translateX(9vw) translateY(31vh) scale(0.26);
  }
  40% {
    transform: translateX(23vw) translateY(26vh) scale(0.69);
  }
  45% {
    transform: translateX(37vw) translateY(22vh) scale(0.65);
  }
  50% {
    transform: translateX(14vw) translateY(16vh) scale(0.43);
  }
  55% {
    transform: translateX(28vw) translateY(-46vh) scale(0.93);
  }
  60% {
    transform: translateX(-44vw) translateY(28vh) scale(0.64);
  }
  65% {
    transform: translateX(28vw) translateY(-2vh) scale(0.63);
  }
  70% {
    transform: translateX(39vw) translateY(4vh) scale(0.96);
  }
  75% {
    transform: translateX(23vw) translateY(4vh) scale(0.95);
  }
  80% {
    transform: translateX(17vw) translateY(-49vh) scale(0.67);
  }
  85% {
    transform: translateX(-34vw) translateY(-42vh) scale(0.34);
  }
  90% {
    transform: translateX(48vw) translateY(-40vh) scale(0.36);
  }
  95% {
    transform: translateX(27vw) translateY(17vh) scale(0.45);
  }
  100% {
    transform: translateX(41vw) translateY(26vh) scale(0.49);
  }
}
.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 13s;
}
.firefly:nth-child(2)::after {
  animation-duration: 13s, 6064ms;
  animation-delay: 0ms, 2862ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-30vw) translateY(-14vh) scale(0.58);
  }
  4.1666666667% {
    transform: translateX(-17vw) translateY(-42vh) scale(0.87);
  }
  8.3333333333% {
    transform: translateX(28vw) translateY(4vh) scale(0.98);
  }
  12.5% {
    transform: translateX(-43vw) translateY(-27vh) scale(0.55);
  }
  16.6666666667% {
    transform: translateX(-33vw) translateY(18vh) scale(0.52);
  }
  20.8333333333% {
    transform: translateX(-43vw) translateY(-47vh) scale(0.31);
  }
  25% {
    transform: translateX(42vw) translateY(22vh) scale(0.89);
  }
  29.1666666667% {
    transform: translateX(18vw) translateY(6vh) scale(0.97);
  }
  33.3333333333% {
    transform: translateX(-2vw) translateY(50vh) scale(0.56);
  }
  37.5% {
    transform: translateX(-31vw) translateY(-27vh) scale(0.97);
  }
  41.6666666667% {
    transform: translateX(14vw) translateY(-18vh) scale(0.61);
  }
  45.8333333333% {
    transform: translateX(10vw) translateY(24vh) scale(0.39);
  }
  50% {
    transform: translateX(23vw) translateY(-25vh) scale(0.95);
  }
  54.1666666667% {
    transform: translateX(-26vw) translateY(33vh) scale(0.52);
  }
  58.3333333333% {
    transform: translateX(41vw) translateY(12vh) scale(0.5);
  }
  62.5% {
    transform: translateX(-47vw) translateY(-41vh) scale(0.57);
  }
  66.6666666667% {
    transform: translateX(-44vw) translateY(-35vh) scale(0.48);
  }
  70.8333333333% {
    transform: translateX(-13vw) translateY(38vh) scale(0.78);
  }
  75% {
    transform: translateX(48vw) translateY(17vh) scale(0.47);
  }
  79.1666666667% {
    transform: translateX(26vw) translateY(-17vh) scale(0.38);
  }
  83.3333333333% {
    transform: translateX(11vw) translateY(-23vh) scale(0.7);
  }
  87.5% {
    transform: translateX(-28vw) translateY(-33vh) scale(0.53);
  }
  91.6666666667% {
    transform: translateX(17vw) translateY(30vh) scale(0.95);
  }
  95.8333333333% {
    transform: translateX(-37vw) translateY(-44vh) scale(0.27);
  }
  100% {
    transform: translateX(-21vw) translateY(26vh) scale(0.67);
  }
}
.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 16s;
}
.firefly:nth-child(3)::after {
  animation-duration: 16s, 5260ms;
  animation-delay: 0ms, 6300ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-17vw) translateY(4vh) scale(0.3);
  }
  4.347826087% {
    transform: translateX(38vw) translateY(-28vh) scale(0.33);
  }
  8.6956521739% {
    transform: translateX(-1vw) translateY(-33vh) scale(0.57);
  }
  13.0434782609% {
    transform: translateX(42vw) translateY(-35vh) scale(0.47);
  }
  17.3913043478% {
    transform: translateX(19vw) translateY(3vh) scale(0.34);
  }
  21.7391304348% {
    transform: translateX(-10vw) translateY(-39vh) scale(0.36);
  }
  26.0869565217% {
    transform: translateX(-26vw) translateY(16vh) scale(0.48);
  }
  30.4347826087% {
    transform: translateX(-18vw) translateY(29vh) scale(0.36);
  }
  34.7826086957% {
    transform: translateX(34vw) translateY(-10vh) scale(0.36);
  }
  39.1304347826% {
    transform: translateX(-28vw) translateY(1vh) scale(0.64);
  }
  43.4782608696% {
    transform: translateX(10vw) translateY(-29vh) scale(0.51);
  }
  47.8260869565% {
    transform: translateX(-26vw) translateY(15vh) scale(0.31);
  }
  52.1739130435% {
    transform: translateX(-28vw) translateY(34vh) scale(0.73);
  }
  56.5217391304% {
    transform: translateX(-39vw) translateY(-1vh) scale(0.3);
  }
  60.8695652174% {
    transform: translateX(-17vw) translateY(-2vh) scale(0.62);
  }
  65.2173913043% {
    transform: translateX(34vw) translateY(3vh) scale(0.52);
  }
  69.5652173913% {
    transform: translateX(43vw) translateY(4vh) scale(0.39);
  }
  73.9130434783% {
    transform: translateX(33vw) translateY(6vh) scale(0.41);
  }
  78.2608695652% {
    transform: translateX(48vw) translateY(-32vh) scale(0.53);
  }
  82.6086956522% {
    transform: translateX(-2vw) translateY(31vh) scale(0.5);
  }
  86.9565217391% {
    transform: translateX(15vw) translateY(33vh) scale(0.77);
  }
  91.3043478261% {
    transform: translateX(-16vw) translateY(11vh) scale(0.26);
  }
  95.652173913% {
    transform: translateX(13vw) translateY(-49vh) scale(0.74);
  }
  100% {
    transform: translateX(-33vw) translateY(5vh) scale(0.87);
  }
}
.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 13s;
}
.firefly:nth-child(4)::after {
  animation-duration: 13s, 9103ms;
  animation-delay: 0ms, 2668ms;
}

@keyframes move4 {
  0% {
    transform: translateX(39vw) translateY(37vh) scale(0.84);
  }
  4.5454545455% {
    transform: translateX(-17vw) translateY(46vh) scale(0.68);
  }
  9.0909090909% {
    transform: translateX(-22vw) translateY(39vh) scale(0.55);
  }
  13.6363636364% {
    transform: translateX(-9vw) translateY(-25vh) scale(0.52);
  }
  18.1818181818% {
    transform: translateX(-22vw) translateY(-7vh) scale(0.3);
  }
  22.7272727273% {
    transform: translateX(27vw) translateY(41vh) scale(0.41);
  }
  27.2727272727% {
    transform: translateX(-26vw) translateY(-7vh) scale(0.84);
  }
  31.8181818182% {
    transform: translateX(34vw) translateY(18vh) scale(0.47);
  }
  36.3636363636% {
    transform: translateX(33vw) translateY(-36vh) scale(0.71);
  }
  40.9090909091% {
    transform: translateX(-44vw) translateY(-26vh) scale(0.43);
  }
  45.4545454545% {
    transform: translateX(23vw) translateY(-20vh) scale(0.86);
  }
  50% {
    transform: translateX(-9vw) translateY(-33vh) scale(0.81);
  }
  54.5454545455% {
    transform: translateX(43vw) translateY(33vh) scale(0.56);
  }
  59.0909090909% {
    transform: translateX(30vw) translateY(33vh) scale(0.33);
  }
  63.6363636364% {
    transform: translateX(30vw) translateY(-38vh) scale(0.26);
  }
  68.1818181818% {
    transform: translateX(-20vw) translateY(-6vh) scale(0.6);
  }
  72.7272727273% {
    transform: translateX(22vw) translateY(-23vh) scale(0.47);
  }
  77.2727272727% {
    transform: translateX(37vw) translateY(15vh) scale(0.93);
  }
  81.8181818182% {
    transform: translateX(-28vw) translateY(-8vh) scale(0.6);
  }
  86.3636363636% {
    transform: translateX(43vw) translateY(47vh) scale(0.34);
  }
  90.9090909091% {
    transform: translateX(-35vw) translateY(-29vh) scale(0.57);
  }
  95.4545454545% {
    transform: translateX(5vw) translateY(-12vh) scale(0.97);
  }
  100% {
    transform: translateX(29vw) translateY(41vh) scale(0.43);
  }
}
.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 17s;
}
.firefly:nth-child(5)::after {
  animation-duration: 17s, 8722ms;
  animation-delay: 0ms, 2223ms;
}

@keyframes move5 {
  0% {
    transform: translateX(28vw) translateY(-29vh) scale(0.91);
  }
  5.5555555556% {
    transform: translateX(-46vw) translateY(27vh) scale(0.93);
  }
  11.1111111111% {
    transform: translateX(14vw) translateY(-45vh) scale(0.62);
  }
  16.6666666667% {
    transform: translateX(50vw) translateY(-7vh) scale(0.66);
  }
  22.2222222222% {
    transform: translateX(2vw) translateY(24vh) scale(0.96);
  }
  27.7777777778% {
    transform: translateX(-9vw) translateY(5vh) scale(0.9);
  }
  33.3333333333% {
    transform: translateX(40vw) translateY(5vh) scale(0.93);
  }
  38.8888888889% {
    transform: translateX(-17vw) translateY(-20vh) scale(0.32);
  }
  44.4444444444% {
    transform: translateX(44vw) translateY(2vh) scale(0.47);
  }
  50% {
    transform: translateX(30vw) translateY(36vh) scale(0.35);
  }
  55.5555555556% {
    transform: translateX(-28vw) translateY(-46vh) scale(0.83);
  }
  61.1111111111% {
    transform: translateX(-13vw) translateY(39vh) scale(0.57);
  }
  66.6666666667% {
    transform: translateX(3vw) translateY(5vh) scale(0.72);
  }
  72.2222222222% {
    transform: translateX(-1vw) translateY(-23vh) scale(0.61);
  }
  77.7777777778% {
    transform: translateX(17vw) translateY(1vh) scale(0.44);
  }
  83.3333333333% {
    transform: translateX(-8vw) translateY(21vh) scale(0.93);
  }
  88.8888888889% {
    transform: translateX(27vw) translateY(-11vh) scale(0.38);
  }
  94.4444444444% {
    transform: translateX(12vw) translateY(39vh) scale(0.42);
  }
  100% {
    transform: translateX(-4vw) translateY(27vh) scale(0.56);
  }
}
.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 11s;
}
.firefly:nth-child(6)::after {
  animation-duration: 11s, 9543ms;
  animation-delay: 0ms, 6051ms;
}

@keyframes move6 {
  0% {
    transform: translateX(9vw) translateY(10vh) scale(0.67);
  }
  4.1666666667% {
    transform: translateX(17vw) translateY(33vh) scale(0.95);
  }
  8.3333333333% {
    transform: translateX(-6vw) translateY(-48vh) scale(0.91);
  }
  12.5% {
    transform: translateX(-29vw) translateY(0vh) scale(0.98);
  }
  16.6666666667% {
    transform: translateX(7vw) translateY(-26vh) scale(0.62);
  }
  20.8333333333% {
    transform: translateX(-43vw) translateY(11vh) scale(0.3);
  }
  25% {
    transform: translateX(-1vw) translateY(3vh) scale(0.36);
  }
  29.1666666667% {
    transform: translateX(-28vw) translateY(-32vh) scale(0.62);
  }
  33.3333333333% {
    transform: translateX(25vw) translateY(28vh) scale(0.92);
  }
  37.5% {
    transform: translateX(32vw) translateY(-23vh) scale(0.78);
  }
  41.6666666667% {
    transform: translateX(14vw) translateY(2vh) scale(0.64);
  }
  45.8333333333% {
    transform: translateX(-49vw) translateY(-24vh) scale(0.87);
  }
  50% {
    transform: translateX(-20vw) translateY(-4vh) scale(0.55);
  }
  54.1666666667% {
    transform: translateX(30vw) translateY(41vh) scale(0.96);
  }
  58.3333333333% {
    transform: translateX(40vw) translateY(-1vh) scale(0.77);
  }
  62.5% {
    transform: translateX(-22vw) translateY(-18vh) scale(0.44);
  }
  66.6666666667% {
    transform: translateX(-43vw) translateY(-13vh) scale(0.65);
  }
  70.8333333333% {
    transform: translateX(32vw) translateY(6vh) scale(0.4);
  }
  75% {
    transform: translateX(-25vw) translateY(49vh) scale(0.62);
  }
  79.1666666667% {
    transform: translateX(-21vw) translateY(-25vh) scale(0.31);
  }
  83.3333333333% {
    transform: translateX(-28vw) translateY(22vh) scale(0.96);
  }
  87.5% {
    transform: translateX(12vw) translateY(21vh) scale(0.29);
  }
  91.6666666667% {
    transform: translateX(-26vw) translateY(-21vh) scale(0.81);
  }
  95.8333333333% {
    transform: translateX(-47vw) translateY(16vh) scale(0.76);
  }
  100% {
    transform: translateX(21vw) translateY(37vh) scale(0.7);
  }
}
.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 12s;
}
.firefly:nth-child(7)::after {
  animation-duration: 12s, 8574ms;
  animation-delay: 0ms, 7939ms;
}

@keyframes move7 {
  0% {
    transform: translateX(7vw) translateY(-35vh) scale(0.52);
  }
  5.2631578947% {
    transform: translateX(-7vw) translateY(6vh) scale(0.78);
  }
  10.5263157895% {
    transform: translateX(-2vw) translateY(45vh) scale(0.32);
  }
  15.7894736842% {
    transform: translateX(-35vw) translateY(26vh) scale(0.71);
  }
  21.0526315789% {
    transform: translateX(-34vw) translateY(-27vh) scale(0.96);
  }
  26.3157894737% {
    transform: translateX(19vw) translateY(-8vh) scale(0.41);
  }
  31.5789473684% {
    transform: translateX(10vw) translateY(34vh) scale(0.89);
  }
  36.8421052632% {
    transform: translateX(19vw) translateY(-43vh) scale(0.32);
  }
  42.1052631579% {
    transform: translateX(15vw) translateY(-23vh) scale(0.34);
  }
  47.3684210526% {
    transform: translateX(9vw) translateY(-15vh) scale(0.36);
  }
  52.6315789474% {
    transform: translateX(-45vw) translateY(44vh) scale(0.69);
  }
  57.8947368421% {
    transform: translateX(-9vw) translateY(-33vh) scale(0.67);
  }
  63.1578947368% {
    transform: translateX(-7vw) translateY(-47vh) scale(0.61);
  }
  68.4210526316% {
    transform: translateX(17vw) translateY(42vh) scale(0.98);
  }
  73.6842105263% {
    transform: translateX(43vw) translateY(8vh) scale(0.45);
  }
  78.9473684211% {
    transform: translateX(-12vw) translateY(10vh) scale(0.8);
  }
  84.2105263158% {
    transform: translateX(49vw) translateY(-25vh) scale(0.27);
  }
  89.4736842105% {
    transform: translateX(11vw) translateY(46vh) scale(0.9);
  }
  94.7368421053% {
    transform: translateX(-26vw) translateY(40vh) scale(0.49);
  }
  100% {
    transform: translateX(-7vw) translateY(11vh) scale(0.85);
  }
}
.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 9s;
}
.firefly:nth-child(8)::after {
  animation-duration: 9s, 6548ms;
  animation-delay: 0ms, 4095ms;
}

@keyframes move8 {
  0% {
    transform: translateX(42vw) translateY(25vh) scale(0.42);
  }
  5% {
    transform: translateX(-40vw) translateY(17vh) scale(0.31);
  }
  10% {
    transform: translateX(-30vw) translateY(-27vh) scale(0.29);
  }
  15% {
    transform: translateX(-44vw) translateY(8vh) scale(0.66);
  }
  20% {
    transform: translateX(28vw) translateY(2vh) scale(0.97);
  }
  25% {
    transform: translateX(-44vw) translateY(-21vh) scale(0.28);
  }
  30% {
    transform: translateX(19vw) translateY(10vh) scale(0.82);
  }
  35% {
    transform: translateX(29vw) translateY(-21vh) scale(0.48);
  }
  40% {
    transform: translateX(35vw) translateY(-3vh) scale(0.88);
  }
  45% {
    transform: translateX(27vw) translateY(-27vh) scale(0.33);
  }
  50% {
    transform: translateX(-4vw) translateY(-33vh) scale(0.63);
  }
  55% {
    transform: translateX(11vw) translateY(-4vh) scale(0.58);
  }
  60% {
    transform: translateX(7vw) translateY(-22vh) scale(0.99);
  }
  65% {
    transform: translateX(-15vw) translateY(-39vh) scale(0.42);
  }
  70% {
    transform: translateX(-16vw) translateY(23vh) scale(0.51);
  }
  75% {
    transform: translateX(-31vw) translateY(13vh) scale(0.93);
  }
  80% {
    transform: translateX(1vw) translateY(-48vh) scale(0.72);
  }
  85% {
    transform: translateX(47vw) translateY(23vh) scale(0.93);
  }
  90% {
    transform: translateX(2vw) translateY(-45vh) scale(0.65);
  }
  95% {
    transform: translateX(2vw) translateY(41vh) scale(0.88);
  }
  100% {
    transform: translateX(31vw) translateY(-4vh) scale(0.41);
  }
}
.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 11s;
}
.firefly:nth-child(9)::after {
  animation-duration: 11s, 7375ms;
  animation-delay: 0ms, 7371ms;
}

@keyframes move9 {
  0% {
    transform: translateX(-11vw) translateY(25vh) scale(0.8);
  }
  4.347826087% {
    transform: translateX(-24vw) translateY(22vh) scale(0.86);
  }
  8.6956521739% {
    transform: translateX(5vw) translateY(25vh) scale(0.56);
  }
  13.0434782609% {
    transform: translateX(14vw) translateY(-4vh) scale(0.52);
  }
  17.3913043478% {
    transform: translateX(39vw) translateY(-21vh) scale(0.66);
  }
  21.7391304348% {
    transform: translateX(40vw) translateY(-5vh) scale(0.65);
  }
  26.0869565217% {
    transform: translateX(0vw) translateY(38vh) scale(0.67);
  }
  30.4347826087% {
    transform: translateX(35vw) translateY(45vh) scale(0.67);
  }
  34.7826086957% {
    transform: translateX(-29vw) translateY(-3vh) scale(0.95);
  }
  39.1304347826% {
    transform: translateX(-32vw) translateY(44vh) scale(0.36);
  }
  43.4782608696% {
    transform: translateX(-29vw) translateY(-21vh) scale(0.38);
  }
  47.8260869565% {
    transform: translateX(5vw) translateY(49vh) scale(0.27);
  }
  52.1739130435% {
    transform: translateX(-22vw) translateY(-33vh) scale(0.59);
  }
  56.5217391304% {
    transform: translateX(6vw) translateY(19vh) scale(0.74);
  }
  60.8695652174% {
    transform: translateX(-19vw) translateY(28vh) scale(0.83);
  }
  65.2173913043% {
    transform: translateX(27vw) translateY(18vh) scale(0.7);
  }
  69.5652173913% {
    transform: translateX(7vw) translateY(-29vh) scale(0.64);
  }
  73.9130434783% {
    transform: translateX(-7vw) translateY(35vh) scale(0.85);
  }
  78.2608695652% {
    transform: translateX(-39vw) translateY(27vh) scale(0.73);
  }
  82.6086956522% {
    transform: translateX(37vw) translateY(-17vh) scale(0.78);
  }
  86.9565217391% {
    transform: translateX(-1vw) translateY(-41vh) scale(0.95);
  }
  91.3043478261% {
    transform: translateX(29vw) translateY(-42vh) scale(0.98);
  }
  95.652173913% {
    transform: translateX(-25vw) translateY(-1vh) scale(0.68);
  }
  100% {
    transform: translateX(-43vw) translateY(17vh) scale(0.79);
  }
}
.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 9s;
}
.firefly:nth-child(10)::after {
  animation-duration: 9s, 9666ms;
  animation-delay: 0ms, 4453ms;
}

@keyframes move10 {
  0% {
    transform: translateX(-3vw) translateY(-37vh) scale(0.92);
  }
  3.8461538462% {
    transform: translateX(-30vw) translateY(16vh) scale(0.72);
  }
  7.6923076923% {
    transform: translateX(21vw) translateY(-41vh) scale(0.97);
  }
  11.5384615385% {
    transform: translateX(34vw) translateY(1vh) scale(0.9);
  }
  15.3846153846% {
    transform: translateX(-4vw) translateY(21vh) scale(0.81);
  }
  19.2307692308% {
    transform: translateX(-24vw) translateY(-42vh) scale(0.46);
  }
  23.0769230769% {
    transform: translateX(-16vw) translateY(-31vh) scale(0.79);
  }
  26.9230769231% {
    transform: translateX(-47vw) translateY(-21vh) scale(0.52);
  }
  30.7692307692% {
    transform: translateX(-3vw) translateY(1vh) scale(0.55);
  }
  34.6153846154% {
    transform: translateX(-2vw) translateY(35vh) scale(0.78);
  }
  38.4615384615% {
    transform: translateX(38vw) translateY(-37vh) scale(0.79);
  }
  42.3076923077% {
    transform: translateX(36vw) translateY(3vh) scale(0.42);
  }
  46.1538461538% {
    transform: translateX(50vw) translateY(48vh) scale(0.27);
  }
  50% {
    transform: translateX(-28vw) translateY(15vh) scale(0.71);
  }
  53.8461538462% {
    transform: translateX(24vw) translateY(-42vh) scale(0.63);
  }
  57.6923076923% {
    transform: translateX(6vw) translateY(-25vh) scale(0.91);
  }
  61.5384615385% {
    transform: translateX(-44vw) translateY(15vh) scale(0.65);
  }
  65.3846153846% {
    transform: translateX(9vw) translateY(35vh) scale(0.85);
  }
  69.2307692308% {
    transform: translateX(-11vw) translateY(-44vh) scale(0.77);
  }
  73.0769230769% {
    transform: translateX(3vw) translateY(32vh) scale(0.86);
  }
  76.9230769231% {
    transform: translateX(30vw) translateY(-5vh) scale(0.47);
  }
  80.7692307692% {
    transform: translateX(29vw) translateY(50vh) scale(0.96);
  }
  84.6153846154% {
    transform: translateX(-24vw) translateY(41vh) scale(0.47);
  }
  88.4615384615% {
    transform: translateX(-34vw) translateY(46vh) scale(0.71);
  }
  92.3076923077% {
    transform: translateX(-47vw) translateY(-26vh) scale(0.42);
  }
  96.1538461538% {
    transform: translateX(-42vw) translateY(37vh) scale(0.73);
  }
  100% {
    transform: translateX(-39vw) translateY(32vh) scale(0.82);
  }
}
.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 16s;
}
.firefly:nth-child(11)::after {
  animation-duration: 16s, 5460ms;
  animation-delay: 0ms, 6937ms;
}

@keyframes move11 {
  0% {
    transform: translateX(-42vw) translateY(-4vh) scale(0.54);
  }
  5.8823529412% {
    transform: translateX(42vw) translateY(-44vh) scale(0.43);
  }
  11.7647058824% {
    transform: translateX(25vw) translateY(45vh) scale(0.72);
  }
  17.6470588235% {
    transform: translateX(22vw) translateY(-8vh) scale(0.89);
  }
  23.5294117647% {
    transform: translateX(-23vw) translateY(-20vh) scale(0.7);
  }
  29.4117647059% {
    transform: translateX(-25vw) translateY(41vh) scale(0.71);
  }
  35.2941176471% {
    transform: translateX(-36vw) translateY(-12vh) scale(0.29);
  }
  41.1764705882% {
    transform: translateX(-13vw) translateY(27vh) scale(0.62);
  }
  47.0588235294% {
    transform: translateX(31vw) translateY(-15vh) scale(0.82);
  }
  52.9411764706% {
    transform: translateX(-30vw) translateY(6vh) scale(0.96);
  }
  58.8235294118% {
    transform: translateX(39vw) translateY(6vh) scale(0.31);
  }
  64.7058823529% {
    transform: translateX(-45vw) translateY(-1vh) scale(0.5);
  }
  70.5882352941% {
    transform: translateX(-28vw) translateY(-4vh) scale(0.31);
  }
  76.4705882353% {
    transform: translateX(9vw) translateY(-10vh) scale(0.83);
  }
  82.3529411765% {
    transform: translateX(-7vw) translateY(-46vh) scale(0.89);
  }
  88.2352941176% {
    transform: translateX(29vw) translateY(-45vh) scale(0.6);
  }
  94.1176470588% {
    transform: translateX(-17vw) translateY(-26vh) scale(0.64);
  }
  100% {
    transform: translateX(26vw) translateY(-47vh) scale(0.51);
  }
}
.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 12s;
}
.firefly:nth-child(12)::after {
  animation-duration: 12s, 5921ms;
  animation-delay: 0ms, 4141ms;
}

@keyframes move12 {
  0% {
    transform: translateX(-1vw) translateY(23vh) scale(0.68);
  }
  4.7619047619% {
    transform: translateX(31vw) translateY(-18vh) scale(0.76);
  }
  9.5238095238% {
    transform: translateX(-18vw) translateY(15vh) scale(0.26);
  }
  14.2857142857% {
    transform: translateX(-26vw) translateY(-45vh) scale(0.65);
  }
  19.0476190476% {
    transform: translateX(-3vw) translateY(11vh) scale(0.82);
  }
  23.8095238095% {
    transform: translateX(36vw) translateY(25vh) scale(0.82);
  }
  28.5714285714% {
    transform: translateX(13vw) translateY(31vh) scale(0.82);
  }
  33.3333333333% {
    transform: translateX(-6vw) translateY(21vh) scale(0.99);
  }
  38.0952380952% {
    transform: translateX(26vw) translateY(42vh) scale(0.84);
  }
  42.8571428571% {
    transform: translateX(-35vw) translateY(17vh) scale(0.6);
  }
  47.619047619% {
    transform: translateX(50vw) translateY(1vh) scale(0.38);
  }
  52.380952381% {
    transform: translateX(23vw) translateY(10vh) scale(0.55);
  }
  57.1428571429% {
    transform: translateX(1vw) translateY(-31vh) scale(0.37);
  }
  61.9047619048% {
    transform: translateX(34vw) translateY(-41vh) scale(0.99);
  }
  66.6666666667% {
    transform: translateX(-41vw) translateY(37vh) scale(0.72);
  }
  71.4285714286% {
    transform: translateX(-28vw) translateY(25vh) scale(0.79);
  }
  76.1904761905% {
    transform: translateX(38vw) translateY(-20vh) scale(0.42);
  }
  80.9523809524% {
    transform: translateX(-16vw) translateY(3vh) scale(0.86);
  }
  85.7142857143% {
    transform: translateX(34vw) translateY(17vh) scale(0.4);
  }
  90.4761904762% {
    transform: translateX(49vw) translateY(8vh) scale(0.51);
  }
  95.2380952381% {
    transform: translateX(-14vw) translateY(18vh) scale(0.81);
  }
  100% {
    transform: translateX(16vw) translateY(11vh) scale(0.33);
  }
}
.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 11s;
}
.firefly:nth-child(13)::after {
  animation-duration: 11s, 9281ms;
  animation-delay: 0ms, 639ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-42vw) translateY(-43vh) scale(0.97);
  }
  4% {
    transform: translateX(10vw) translateY(39vh) scale(0.82);
  }
  8% {
    transform: translateX(39vw) translateY(-49vh) scale(0.5);
  }
  12% {
    transform: translateX(-9vw) translateY(50vh) scale(0.65);
  }
  16% {
    transform: translateX(-15vw) translateY(28vh) scale(0.39);
  }
  20% {
    transform: translateX(27vw) translateY(12vh) scale(0.46);
  }
  24% {
    transform: translateX(42vw) translateY(39vh) scale(0.6);
  }
  28% {
    transform: translateX(15vw) translateY(42vh) scale(0.97);
  }
  32% {
    transform: translateX(25vw) translateY(0vh) scale(0.63);
  }
  36% {
    transform: translateX(14vw) translateY(11vh) scale(0.6);
  }
  40% {
    transform: translateX(-48vw) translateY(47vh) scale(0.59);
  }
  44% {
    transform: translateX(7vw) translateY(28vh) scale(0.86);
  }
  48% {
    transform: translateX(27vw) translateY(30vh) scale(0.62);
  }
  52% {
    transform: translateX(-23vw) translateY(-14vh) scale(0.4);
  }
  56% {
    transform: translateX(10vw) translateY(18vh) scale(0.47);
  }
  60% {
    transform: translateX(-5vw) translateY(-26vh) scale(0.7);
  }
  64% {
    transform: translateX(-22vw) translateY(-4vh) scale(0.9);
  }
  68% {
    transform: translateX(9vw) translateY(-28vh) scale(0.38);
  }
  72% {
    transform: translateX(-39vw) translateY(25vh) scale(0.41);
  }
  76% {
    transform: translateX(-33vw) translateY(33vh) scale(0.61);
  }
  80% {
    transform: translateX(42vw) translateY(29vh) scale(0.79);
  }
  84% {
    transform: translateX(-21vw) translateY(-13vh) scale(0.57);
  }
  88% {
    transform: translateX(3vw) translateY(-39vh) scale(0.3);
  }
  92% {
    transform: translateX(33vw) translateY(-35vh) scale(0.26);
  }
  96% {
    transform: translateX(11vw) translateY(-34vh) scale(0.69);
  }
  100% {
    transform: translateX(-29vw) translateY(27vh) scale(0.56);
  }
}
.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 14s;
}
.firefly:nth-child(14)::after {
  animation-duration: 14s, 5989ms;
  animation-delay: 0ms, 2923ms;
}

@keyframes move14 {
  0% {
    transform: translateX(39vw) translateY(-19vh) scale(0.8);
  }
  3.5714285714% {
    transform: translateX(33vw) translateY(-18vh) scale(0.34);
  }
  7.1428571429% {
    transform: translateX(33vw) translateY(35vh) scale(0.86);
  }
  10.7142857143% {
    transform: translateX(-44vw) translateY(30vh) scale(0.31);
  }
  14.2857142857% {
    transform: translateX(-33vw) translateY(26vh) scale(0.77);
  }
  17.8571428571% {
    transform: translateX(-37vw) translateY(28vh) scale(0.65);
  }
  21.4285714286% {
    transform: translateX(-31vw) translateY(-15vh) scale(0.92);
  }
  25% {
    transform: translateX(18vw) translateY(32vh) scale(0.45);
  }
  28.5714285714% {
    transform: translateX(-41vw) translateY(42vh) scale(0.39);
  }
  32.1428571429% {
    transform: translateX(10vw) translateY(-13vh) scale(0.33);
  }
  35.7142857143% {
    transform: translateX(9vw) translateY(-40vh) scale(0.66);
  }
  39.2857142857% {
    transform: translateX(15vw) translateY(-42vh) scale(0.84);
  }
  42.8571428571% {
    transform: translateX(11vw) translateY(40vh) scale(0.62);
  }
  46.4285714286% {
    transform: translateX(29vw) translateY(15vh) scale(0.57);
  }
  50% {
    transform: translateX(5vw) translateY(45vh) scale(0.66);
  }
  53.5714285714% {
    transform: translateX(16vw) translateY(26vh) scale(0.59);
  }
  57.1428571429% {
    transform: translateX(-39vw) translateY(5vh) scale(0.69);
  }
  60.7142857143% {
    transform: translateX(6vw) translateY(38vh) scale(0.65);
  }
  64.2857142857% {
    transform: translateX(-31vw) translateY(-16vh) scale(0.69);
  }
  67.8571428571% {
    transform: translateX(26vw) translateY(-41vh) scale(0.74);
  }
  71.4285714286% {
    transform: translateX(-23vw) translateY(-12vh) scale(0.8);
  }
  75% {
    transform: translateX(26vw) translateY(-37vh) scale(0.73);
  }
  78.5714285714% {
    transform: translateX(24vw) translateY(-32vh) scale(0.35);
  }
  82.1428571429% {
    transform: translateX(-45vw) translateY(-48vh) scale(0.95);
  }
  85.7142857143% {
    transform: translateX(-3vw) translateY(-29vh) scale(0.75);
  }
  89.2857142857% {
    transform: translateX(20vw) translateY(-43vh) scale(0.4);
  }
  92.8571428571% {
    transform: translateX(31vw) translateY(-24vh) scale(0.94);
  }
  96.4285714286% {
    transform: translateX(12vw) translateY(11vh) scale(0.71);
  }
  100% {
    transform: translateX(-6vw) translateY(33vh) scale(0.46);
  }
}
.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 10s;
}
.firefly:nth-child(15)::after {
  animation-duration: 10s, 8619ms;
  animation-delay: 0ms, 1529ms;
}

@keyframes move15 {
  0% {
    transform: translateX(-29vw) translateY(9vh) scale(0.4);
  }
  5.2631578947% {
    transform: translateX(14vw) translateY(-42vh) scale(0.65);
  }
  10.5263157895% {
    transform: translateX(-48vw) translateY(8vh) scale(0.46);
  }
  15.7894736842% {
    transform: translateX(-14vw) translateY(-22vh) scale(0.66);
  }
  21.0526315789% {
    transform: translateX(-15vw) translateY(2vh) scale(0.9);
  }
  26.3157894737% {
    transform: translateX(-34vw) translateY(33vh) scale(0.45);
  }
  31.5789473684% {
    transform: translateX(-4vw) translateY(19vh) scale(0.48);
  }
  36.8421052632% {
    transform: translateX(15vw) translateY(21vh) scale(0.68);
  }
  42.1052631579% {
    transform: translateX(-15vw) translateY(40vh) scale(0.78);
  }
  47.3684210526% {
    transform: translateX(-8vw) translateY(1vh) scale(0.44);
  }
  52.6315789474% {
    transform: translateX(-46vw) translateY(29vh) scale(0.31);
  }
  57.8947368421% {
    transform: translateX(-6vw) translateY(32vh) scale(0.35);
  }
  63.1578947368% {
    transform: translateX(12vw) translateY(28vh) scale(0.93);
  }
  68.4210526316% {
    transform: translateX(33vw) translateY(37vh) scale(0.53);
  }
  73.6842105263% {
    transform: translateX(-9vw) translateY(31vh) scale(0.94);
  }
  78.9473684211% {
    transform: translateX(-16vw) translateY(-42vh) scale(0.4);
  }
  84.2105263158% {
    transform: translateX(33vw) translateY(15vh) scale(0.35);
  }
  89.4736842105% {
    transform: translateX(10vw) translateY(19vh) scale(0.44);
  }
  94.7368421053% {
    transform: translateX(-15vw) translateY(7vh) scale(0.8);
  }
  100% {
    transform: translateX(-48vw) translateY(-17vh) scale(0.63);
  }
}
@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}

.link {
  display: inline-block;
  position: relative;
  color: #fff;
}

.link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
